<template>
  <div>
    <polygon-background
      :styles="{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }"
      :animateHexagons.sync="animatePolygons"
    />
    <v-container fluid class="email-verify-page" fill-height>
      <v-row no-gutters justify="center" align="center">
        <v-col cols="12" md="6">
          <v-alert
            prominent
            :type="alertType"
            class=" mb-7 rounded-0 darken-1"
          >
            <v-row v-show="verified" align="center">
              <v-col class="grow">
                <span>
                  {{ $t("EmailVerified") }}
                </span>
              </v-col>
            </v-row>            
            <p class="mb-0 text-center">
              <devbey-logo-loader :size="75" :color="'white'" />                          
            </p>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PolygonBackground      from "../../components/Util/PolygonBackground";
import DevbeyLogoLoader       from "../../components/Util/DevbeyLogoLoader";

export default {
  name: "VerifyEmailPage",
  components: {
    PolygonBackground,
    DevbeyLogoLoader
  },
  computed: {
    alertType() {
      return this.verified ? 'success' : 'error';
    }
  },
  data() {
    return {
      animatePolygons: false,
      verified: false,
    };
  },
  methods: {
    verify(url) {
      this.$store.dispatch("auth/verifyEmail", url).then(resp => {
        if (resp.satus == 202) this.verified = true;
      })
    }	
  },
  mounted() {
    if (this.$route.query.url) this.verify(this.$route.query.url);
  }
};
</script>

<style lang="scss" scoped>
.email-verify-page {
  height: 80vh;
}
</style>
